
import Vue from 'vue';
import ContactButton from '../modules/modals/contact_button.vue';
import Buefy from 'buefy';
import axios from 'axios';

Vue.use(Buefy)

document.addEventListener('DOMContentLoaded', () => {
  var element = document.getElementById("contact_modal")
  if (element != null) {
    const app = new Vue({
      el: element,
      render: h => h(ContactButton),
      data: {},
    }).$mount()
  }
})
